import styled from 'styled-components';
import { RelSize, Ratio } from '../mixins';
import theme from '../theme';

export const StyledHeader = styled.header`
    padding: 0 ${RelSize(20, 16)};
    position: relative;
    width: 100%;
    z-index: 10;
    a {
        display: block;
    }
`;

export const ExternalHeaderStyled = styled.header`
	align-items: center;
	background-color: ${theme.backgroundGrey};
	display: flex;
	justify-content: center;
	height: ${RelSize(90, 16)};
    padding: ${RelSize(20, 16)} ${RelSize(50, 16)};
    position: relative;
    width: 100%;
    z-index: 10;
`;

export const ContentHeaderStyled = styled.header`
	align-items: center;
	display: flex;
	justify-content: space-between;
    padding: ${RelSize(20, 16)};
    position: relative;
    width: 100%;
    z-index: 10;
	ul {
		align-items: center;
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0;
			&:not(:last-child) {
				margin-right: ${RelSize(20, 16)};
			}
			&:last-child {
				> a {
					color: ${theme.primaryGreen};
					font-size: ${RelSize(14, 16)};
					line-height: ${Ratio(14)};
				}
			}
			@media all and (max-width: 768px) {
				margin-top: ${RelSize(10, 16)};
			}
		}
		@media all and (max-width: 768px) {
			flex-wrap: wrap;
			justify-content: center;
			margin-top: ${RelSize(10, 16)};
		}
	}
	@media all and (max-width: 768px) {
		flex-direction: column;
	}
`;

export const HeaderAuthWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;

export const HeaderAuthSubWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
`;

export const HeaderAuthItemWrapper = styled.div`
	align-items: center;
	background-color: ${theme.white};
	border: 1px solid ${theme.backgroundGrey};
	border-radius: ${RelSize(20, 16)};
	display: flex;
	justify-content: flex-end;
	padding: ${RelSize(5, 16)};
	&:not(:last-child) {
		margin-right: ${RelSize(5, 16)};
	}
`;

export const HeaderUserWrapper = styled.div`
	border: 1px solid ${theme.backgroundGrey};
	border-radius: 50%;
	height: ${RelSize(40, 16)};
	overflow: hidden;
	width: ${RelSize(40, 16)};
	a {
		align-items: center;
		display: flex;
		border-radius: 50%;
		height: ${RelSize(40, 16)};
		justify-content: center;
		overflow: hidden;
		padding: ${RelSize(7, 16)};
		width: ${RelSize(40, 16)};
		img {
			display: block;
			width: 100%;
		}
	}
`;