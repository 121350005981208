import Link from "next/link";
import { ContentHeaderStyled } from "../../styles/header";
import Logo from "../icons/logo";
import { StandardButtonAnchor } from "../../styles/type/buttons";
import { useRouter } from "next/router";
import RegisterInterest from "../registerInterest";

const ContentHeader = ({ auth, handleScrollToTarget }) => {
	const router = useRouter();
	return (
		<ContentHeaderStyled>
			<Link href="/about">
				<a>
					<Logo />
				</a>
			</Link>
			<ul>
				{!auth ? (
					<>
						<li>
							<RegisterInterest demo={true} />
						</li>
						{handleScrollToTarget ? (
							<li>
								<StandardButtonAnchor small onClick={handleScrollToTarget}>
									Testimonials
								</StandardButtonAnchor>
							</li>
						) : null}
						<li>
							<StandardButtonAnchor small href={`https://www.linkedin.com/company/edun-crm/?originalSubdomain=uk`} target="_blank" rel="noopener noreferrer">
								LinkedIn
							</StandardButtonAnchor>
						</li>
						<li>
							<StandardButtonAnchor small onClick={() => router.push("mailto:info@helloedun.co.uk")}>
								Contact Us
							</StandardButtonAnchor>
						</li>
					</>
				) : null}
				<li>
					<Link href={auth ? "/" : "/login"}>
						<a>{auth ? "Dashboard" : "Log In Here"}</a>
					</Link>
				</li>
			</ul>
		</ContentHeaderStyled>
	);
};

export default ContentHeader;
