import styled from 'styled-components'
import { RelSize } from '../mixins'
import theme from '../theme'

export const StandardContain = styled.div`
    margin: ${props => props.push ? RelSize(50, 16) : 0} auto 0;
    max-width: ${props => props.small ? theme.smallMaxWidth : theme.maxWidth};
    padding: ${RelSize(75, 16)} ${RelSize(50, 16)};
    width: 100%;
    @media all and (max-width: 1024px) {
        padding: ${RelSize(50, 16)} ${RelSize(20, 16)};
    }
	@media all and (max-height: 800px) {
		padding: ${RelSize(50, 16)} ${RelSize(20, 16)};
	}
`;

export const LayoutContain = styled.div`
    margin: ${props => props.push ? RelSize(50, 16) : 0} auto 0;
    max-width: ${props => props.small ? theme.smallMaxWidth : theme.maxWidth};
    padding: ${RelSize(15, 16)} ${RelSize(50, 16)} ${RelSize(75, 16)};
    width: 100%;
    @media all and (max-width: 1024px) {
        padding: ${RelSize(10, 16)} ${RelSize(20, 16)} ${RelSize(50, 16)};
    }
	@media all and (max-height: 800px) {
		padding: ${RelSize(10, 16)} ${RelSize(20, 16)} ${RelSize(50, 16)};
	}
`;

export const HeaderContain = styled.div`
	border-bottom: 1px solid ${theme.backgroundGrey};
	padding: ${RelSize(15, 16)} 0;
    width: 100%;
	> div {
		margin: 0 auto;
		max-width: ${theme.maxWidth};
		padding: 0 ${RelSize(50, 16)} 0;
		@media all and (max-width: 1024px) {
			padding: 0 ${RelSize(20, 16)} 0;
		}
		@media all and (max-height: 800px) {
			padding: 0 ${RelSize(20, 16)} 0;
		}
	}
`;

export const FullContain = styled.main`
    margin: 0 auto;
    padding: ${RelSize(20, 16)} ${RelSize(50, 16)};
    transition: padding ease-in 0.2s;
    width: 100%;
    @media all and (max-width: 1024px) {
        padding: ${RelSize(20, 16)};
    }
`;

export const ContentContain = styled.div`
    margin: 0 auto;
    max-width: ${props => props.small ? theme.smallMaxWidth : theme.maxWidth};
    padding: ${RelSize(75, 16)}  ${RelSize(50, 16)};
    width: 100%;
	${props => props.flushTop && `
		padding-top: 0;
	`}
    @media all and (max-width: 1024px) {
        padding: ${RelSize(75, 16)}  ${RelSize(20, 16)};
    }
`;

export const ClearContain = styled.div`
    background-color: ${theme.trueWhite};
	width: 100%;
`;

export const PageContent = styled.div`
	background-color: ${theme.white};
	border-bottom-left-radius: ${RelSize(30, 16)};
	border-top-left-radius: ${RelSize(30, 16)};
	margin-left: ${RelSize(65, 16)};
    min-height: 100vh;
	${props => !props.onload && `
		transition: all ease-in 0.2s;
		transition-delay: ${props => props.navigationHidden ? "0.2s" : "0.01s"};
	`}
	@media all and (min-width: 1441px) {
		box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
		margin-left: ${props => props.navigationHidden ? RelSize(65, 16) : RelSize(250, 16)};
	}
	@media all and (min-width: 1024px) and (max-width: 1440px) {
		box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
		margin-left: ${props => props.navigationHidden ? RelSize(65, 16) : RelSize(200, 16)};
	}
`;

export const ExternalPageContent = styled.div`
	background-color: ${({ clear }) => clear ? theme.white : theme.backgroundGrey};
    min-height: 100vh;
	@media all and (max-width: 768px) {
		background-color: transparent;
	}
`;

export const NoAuthContent = styled.div`
	align-items: center;
	background-color: ${theme.greenStd};
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	padding: ${RelSize(20, 16)};
	@media all and (min-width: 769px) {
		> div {
			&:not(:last-child) {
				margin-right: ${RelSize(50, 16)};
			}
		}
	}
	@media all and (max-width: 768px) {
		flex-direction: column;
		> div {
			&:not(:last-child) {
				margin-bottom: ${RelSize(50, 16)};
			}
		}
	}
`;

export const ErrorContent = styled.div`
	align-items: flex-start;
	background-color: ${theme.greenStd};
	display: flex;
	justify-content: center;
	flex-direction: column;
	min-height: 100vh;
	padding: ${RelSize(20, 16)};
	@media all and (min-width: 769px) {
		> div {
			&:not(:last-child) {
				margin-right: ${RelSize(50, 16)};
			}
		}
	}
	@media all and (max-width: 768px) {
		flex-direction: column;
		> div {
			&:not(:last-child) {
				margin-bottom: ${RelSize(50, 16)};
			}
		}
	}
`;

export const StandardContent = styled.div`
	background-color: ${theme.backgroundGrey};
	min-height: 100vh;
`;

export const StandardContentChildren = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	min-height: calc(100vh - ${RelSize(85, 16)});
`;