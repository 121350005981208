import React, { useState } from "react";
import { connect } from "react-redux";
import { toggleLoading } from "../../state/creators";
import { RegisterModel } from "../../_models/data.register.model";
import { ApiResultModel } from "../../_models/api.result.model";
import { ValidateRegisterForUpdates } from "../../functions/validators";
import { Register } from "../../functions/http-functions/register";
import Email from "../forms/inputs/email";
import Logo from "../icons/logo";
import { RegisterModalWrapper, RegisterModalContent, RegisterModalHeader, RegisterModalFooter } from "../../styles/modals";
import { ButtonList, SubmitButton } from "../../styles/type/buttons";
import { Form } from "../../styles/forms";
import { IHttpResponse } from "../../functions/http-functions/http";

const ConfirmModal = ({ closeModal }) => {
	const [registerEmail, updateEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [registerSuccess, setRegisterSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = ({ target: { name, value, type, checked } }) => {
		if (name === "registerEmail") updateEmail(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setEmailError(null);
		const ValidateStep = ValidateRegisterForUpdates({
			email: registerEmail,
		});
		if (ValidateStep.valid) {
			register();
		} else {
			for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
				setEmailError(item.error);
			}
		}
	};

	const register = async () => {
		toggleLoading(true);
		setLoading(true);
		Register(new RegisterModel({ email: registerEmail }))
			.then((res) => setRegisterSuccess(true))
			.catch((err: unknown) => {
				const error = err as IHttpResponse;
				const ErrorResult = new ApiResultModel(error);
				setEmailError(ErrorResult.message);
			})
			.then(() => {
				toggleLoading(false);
				setLoading(false);
			});
	};

	return (
		<>
			<RegisterModalWrapper>
				<RegisterModalContent>
					<RegisterModalHeader>
						<Logo small />
					</RegisterModalHeader>
					{registerSuccess ? (
						<>
							<RegisterModalHeader>
								<h2>Interest Registered!</h2>
								<p>Keep an eye on your inbox for update emails from the Edun team.</p>
							</RegisterModalHeader>
							<ButtonList>
								<SubmitButton full onClick={closeModal}>
									Got It!
								</SubmitButton>
							</ButtonList>
						</>
					) : (
						<>
							<RegisterModalHeader>
								<h2>Thanks for taking interest!</h2>
								<p>Please let us know your preferred email address so one of our onboarding team can get in touch!</p>
							</RegisterModalHeader>
							<Form>
								<Email name="registerEmail" label="Email" value={registerEmail} onChange={handleChange} error={emailError} />
								<ButtonList>
									<SubmitButton full onClick={handleSubmit} loading={loading ? "true" : undefined}>
										Register Interest
									</SubmitButton>
								</ButtonList>
							</Form>
						</>
					)}
				</RegisterModalContent>
				{!registerSuccess ? (
					<RegisterModalFooter>
						<a onClick={closeModal}>I don&apos;t want to register my interest</a>
					</RegisterModalFooter>
				) : null}
			</RegisterModalWrapper>
		</>
	);
};

const mapDispatchToProps = {
	toggleLoading,
};

export default connect(null, mapDispatchToProps)(ConfirmModal);
