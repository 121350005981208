import styled from 'styled-components';
import theme from '../theme';
import { RelSize } from "../mixins";

export const StyledFooter = styled.footer`
	background-color: ${theme.trueWhite};
	border-top: 1px solid ${theme.borderGrey};
`;

export const StyledFooterInner = styled.div`
	@media all and (min-width: 769px) {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	p {
		color: ${theme.secondaryGrey};
		font-size: ${RelSize(14, 16)};
	}
	a {
		color: ${theme.primaryGreen};
	}
	ul {
		display: flex;
		flex-direction: row;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			font-size: ${RelSize(14, 16)};
			&:not(:last-child) {
				margin-right: ${RelSize(20, 14)};
			}
		}
	}
`;