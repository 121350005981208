import React, { useState, useEffect } from "react";
import { Props } from "../../../_interfaces/component.inputText.interface";
import { FormRow, FormRowInner, Label, ReadOnly, CanEdit } from "../../../styles/forms/inputs";
import InputError from "../errors/input";

const EmailInput: React.FC<Props> = ({
	label = false,
	light = false,
	help = false,
	placeholder = null,
	name,
	value,
	readonly = false,
	error = null,
	inline = false,
	onChange,
	required = false,
	canEditReadOnly = null,
}) => {
	let timeout: any = null;

	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const handleChange = (e) => {
		e.persist();
		setInputValue(e.target.value);
		timeout = setTimeout(() => {
			clearTimeout(timeout);
			handleBlur(e);
		}, 100);
	};

	const handleBlur = (e) => {
		onChange(e);
	};

	useEffect(() => {
		setInputError(error);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	useEffect(() => {
		if (readonly !== isReadOnly) setReadOnly(readonly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonly]);

	useEffect(() => {
		if (canEditReadOnly !== null && !canEditReadOnly) setReadOnly(true);
	}, [canEditReadOnly]);

	return (
		<FormRow hasError={inputError ? true : false}>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
					</Label>
				)}
				{isReadOnly ? (
					<ReadOnly canEdit={canEditReadOnly}>
						{inputValue}
						{canEditReadOnly ? <CanEdit onClick={() => setReadOnly(!isReadOnly)} /> : null}
					</ReadOnly>
				) : (
					<input
						type="text"
						placeholder={!label ? placeholder || "" : ""}
						name={name}
						value={inputValue}
						onChange={(e) => handleChange(e)}
						onBlur={(e) => handleBlur(e)}
						readOnly={isReadOnly ? true : false}
					/>
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default EmailInput;
