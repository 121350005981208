import { useState } from "react";
import Modal from "react-modal";
import RegisterModal from "../modals/registerModal";
import { StandardButtonAnchor } from "../../styles/type/buttons";
import { RegisterStyles } from "../../styles/modals";

const RegisterInterest = ({ demo }: { demo?: boolean }) => {
	const [modalIsOpen, setIsOpen] = useState(false);

	return (
		<div>
			<StandardButtonAnchor small={demo ? true : false} onClick={() => setIsOpen(!modalIsOpen)}>
				{demo ? "Request Demo" : "Register Interest"}
			</StandardButtonAnchor>
			<Modal isOpen={modalIsOpen} style={RegisterStyles} contentLabel="Register Modal" shouldCloseOnOverlayClick={false} ariaHideApp={false}>
				<RegisterModal closeModal={() => setIsOpen(false)} />
			</Modal>
		</div>
	);
};

export default RegisterInterest;
