import Router from "next/router";
import NProgress from "nprogress";
import ContentHeader from "../header/contentHeader";
import { StandardContent, StandardContentChildren } from "../../styles/layout/contain";
import StandardFooter from "../footer/standardFooter";

Router.events.on("routeChangeStart", () => {
	NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
	NProgress.done();
});
Router.events.on("routeChangeError", () => {
	NProgress.done();
});

const ContentLayout = ({ children = null, breadcrumb = [], auth = false, handleScrollToTarget = null }) => (
	<>
		<StandardContent breadcrumb={breadcrumb.length ? true : false}>
			<ContentHeader auth={auth} handleScrollToTarget={handleScrollToTarget} />
			<StandardContentChildren>{children}</StandardContentChildren>
			<StandardFooter />
		</StandardContent>
	</>
);

export default ContentLayout;
