import { HeadersNoAuth, HTTP, IHttpResponse } from "./http";
import { REGISTER } from "../../config/endpoints";

export const Register = (DATA) => {
	return HTTP({
		Method: "POST",
		Url: REGISTER,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};
