import Link from "next/link";
import { StyledFooter, StyledFooterInner } from "../../styles/footer";
import { StandardContain } from "../../styles/layout/contain";

const StandardFooter = () => (
	<StyledFooter>
		<StandardContain>
			<StyledFooterInner>
				<p>&copy; Copyright edun {new Date().getFullYear()}. All Rights Reserved</p>
				<ul>
					<li>
						<Link href="/about/cookies">Cookie Policy</Link>
					</li>
					<li>
						<Link href="/about/privacy-policy">Privacy Policy</Link>
					</li>
				</ul>
			</StyledFooterInner>
		</StandardContain>
	</StyledFooter>
);

export default StandardFooter;
